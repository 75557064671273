<template>
    <div class="w-p-100 h-p-100 bg-f3">
      <van-form
        class="h-p-100 scroll-y w-p-100"
        @submit="onSubmit"
        ref="detailSubmit"
      >
        <van-cell-group inset class="h-calc-45 scroll-y margin-0 bg-transparent">
            <van-field
            v-model="state.risk_judgement_date" disabled
            name="calendar"
            placeholder="点击选择日期"
            @click="showCalendar = true"
            label="日期"
            />


            <!-- 人员信息 -->
            <div class=" tM-10 Fbg flex flex-warp size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">人员信息</div>
                <van-field
                v-model="detailForm.cepsa_num"
                name="cepsa_num"
                label="西萨人数"
                />
                <van-field
                v-model="detailForm.contractor_num"
                name="contractor_num"
                label="承包商人数"
                />
                <van-field
                is-link
                v-model="state.ct_contractor_info_id"
                name="ct_contractor_info_id"
                placeholder="点击选择承包商"
                @click="picker.ct_contractor_info_id=true"
                label="涉及承包商"
                />
            </div>
            
            <!-- -风险研判内容 -->
            <div class=" tM-10 Fbg size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">风险研判内容</div>
                <div v-for="(item,index) in detailForm.RISK_JUDGEMENT_DETAIL" :key="index">
                    <div class="bM-20">
                        <div>{{(index+1)+'. '+item.detail}}</div>
                        <div>
                            <van-radio-group v-model="item.choice" style="border:none" :disabled="item.is_editable==0">
                                <van-radio v-for="(items,index) in options.cemschoice" :key="index" :name="Number(items.PARAMETER_VALUE)">{{ items.PARAMETER_NAME }}</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="bM-20" v-if="item.choice==2">
                        <div>针对此项，将采取如下临时措施：</div>
                        <van-field v-model="item.commention"/>
                    </div>
                </div>
            </div>

            <!-- 备注 -->
            <div class=" tM-10 Fbg flex flex-warp size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">备注</div>
                <van-field
                v-model="detailForm.commention"
                name="commention"
                />
            </div>
            
            <van-popup v-model:show="picker.ct_contractor_info_id" position="bottom">
                <div style="height:400px">
                    <div class="flex justifyBetween h-45">
                        <div class="h-p-100 flex justifyCenter alignCenter size-14 w-60" @click="picker.ct_contractor_info_id = false">取消</div>
                        <div class="h-p-100 flex justifyCenter alignCenter size-14 w-60" @click="confirmCT()">确认</div>
                    </div>
                    <div class="h-calc-45 lP-24">
                        <van-checkbox-group v-model="contractorIds"  ref="checkboxes">
                            <van-checkbox v-for="(item, index) in options.ct_contractor_info_id" :key="index" 
                                :name="item.value.toString()">{{item.label}}
                            </van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
            </van-popup>
            <van-calendar
            v-model:show="showCalendar"
            @confirm="calendarOnConfirm"
            :min-date="new Date('2000-01-01')"
            />
        </van-cell-group>
        <!-- 签名 -->
        <handwritingHtml
            :showVisible="showSignDialog" :echoSignImage = "detailForm.signature"
            @writingCommit="writingCommit"
            @close="showSignDialog = false"
            ref="wirteDialog"
        ></handwritingHtml>
        <div class="flex">
          <van-button
            block
            type="primary"
            @click="save(0)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >保存</van-button
          >
          <van-button
            block
            type="primary"
            @click="save(1)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1&&can_submit==1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >提交</van-button
          >
          <van-button
            block
            type="primary"
            @click="save(1)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1&&can_help_submit==1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >代提交</van-button
          >
          <van-button
            block
            type="default"
            class="bg-808080 color-fff"
            @click="goBack()"
            >返回</van-button
          >
        </div>
      </van-form>
    </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted,reactive, ref} from 'vue';
import http from '../../../api/http';
import {Toast,Notify,Dialog ,ImagePreview} from 'vant'
import { remove } from '@vue/shared';
import dayjs from 'dayjs';
import { useRouter,useRoute } from 'vue-router';
import handwritingHtml from '../../../components/sign.vue';

const router = useRouter();
const route = useRoute(); 

import global from '../../../api/global';
const permission = reactive(global.getPagePermission(route.meta.parent));

const showCalendar = ref(false)
const calendarOnConfirm = (date) =>{
    detailForm.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    state.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');
    showCalendar.value = false;
}
const goBack = ()=>{
        router.push({
            path:'/ESG',
            query:route.query
        })
}
const detailSubmit = ref(null);
const detailForm = reactive({
    method:'saveorupdate',
    t:'RISK_JUDGEMENT',
    ct_contractor_info_id:'',
    id:'',
    RISK_JUDGEMENT_DETAIL:[],
    cepsa_num:'',
    contractor_num:'',
    commention:'',
    risk_judgement_date:dayjs().format('YYYY-MM-DD'),
    type:'4',
    status:'',
    create_by:'',
    create_time:'',
    update_by:'',
    update_time:'',
    signature:''
});
const state = reactive({
    method:'saveorupdate',
    t:'RISK_JUDGEMENT',
    ct_contractor_info_id:'',
    id:'',
    RISK_JUDGEMENT_DETAIL:[],
    cepsa_num:'',
    contractor_num:'',
    commention:'',
    risk_judgement_date:dayjs().format('YYYY-MM-DD'),
    type:'4',
    status:'',
    create_by:'',
    create_time:'',
    update_by:'',
    update_time:'',
    signature:''
});
const checkboxes = ref(null)
const contractorIds = ref([])
const toggle = (item, index) =>{
    checkboxes[index].toggle();
    // 使用 findIndex 查找数组中是否已经存在相同的元素
    const idx = contractorIds.value.findIndex(ele => ele.value === item.value);
    if (idx > -1) {
        // 如果数组中已经存在该元素，则将其删除
        contractorIds.value.splice(idx, 1);
    } else {
        // 否则将其添加到数组中
        contractorIds.value.push(item);
    }
}
const confirmCT = () =>{
    detailForm.ct_contractor_info_id = contractorIds.value.join(','); 

    let show = []
    if(contractorIds.value.length>0){
        contractorIds.value.forEach(item=>{
            const elem = options.ct_contractor_info_id.find(e=>e.value==item);
            if(elem){
                show.push(elem.label);
                
            }  
        })
    }

    state.ct_contractor_info_id = show.join(',');
    picker.ct_contractor_info_id = false;
}

const loading = ref(false);
const list = ref([]);
const fileList = ref([]);

const options = reactive({
    ct_contractor_info_id:[],
    cemschoice:[]
});
const picker = reactive({
    ct_contractor_info_id:false,
});

const getSelectList = () =>{
    http.get({
        method:'listMultiPardata',
        t:'RISK_JUDGEMENT',
        prop:'ct_contractor_info_id'
    },true)
    .then(res=>{
        options.ct_contractor_info_id=res.resultData.ct_contractor_info_id;
        getDetail(route.params.id);

    })
    http.get({
        method:'query',
        queryId:3008,
        parameter_types:"RISK_JUDGEMENT_CHOICE"
    },true)
    .then(res=>{
        options.cemschoice = res.resultData.RISK_JUDGEMENT_CHOICE;
    })
}


const btnLoading = ref(false);
const showSignDialog = ref(false);
// 上传签名
const writingCommit = (res)=>{
    showSignDialog.value = false
    detailForm.signature = res;
    saveRequest(1)
    
}
const save = (type) =>{
    let flag = false
    detailForm.RISK_JUDGEMENT_DETAIL.forEach(item=>{
        if(item.choice==2 && (item.commention == ''||item.commention == null)){
            flag = true
        }
    })
    if(flag){
        Notify({ type: 'warning', message: '请填写风险研判临时措施!' });
        return
    }

    if (type!=0) {
        //判断用户是否已经签名
        if(detailForm.signature != null && detailForm.signature){
            showSignDialog.value = true
        }else {
            if(localStorage.getItem('app_user_id')){
                    http.get({ method:"detailUser", id:localStorage.getItem('app_user_id'), table:"SYS_USER"},true,true)
                .then(res=>{
                    if(res.resultData.map && res.resultData.map.signature_path){
                        detailForm.signature = res.resultData.map.signature_path;
                    }
                    showSignDialog.value = true
                })
                .catch(()=>{})
                }else {
                    showSignDialog.value = true
                }
        }
    } else {
        btnLoading.value = true;
        saveRequest(type);
    }
}
const saveRequest = (type,noMsg) =>{
    var params = Object.assign({}, detailForm)
    if(type==1){
        params['action']='success'
    }
    http.post(params)
    .then(res=>{
        btnLoading.value = false;
        if(!noMsg){
            Notify({ type: 'success', message: '保存成功' });
        }
        getDetail(res.map)
        replaceRoute(res.map)
        getSelectList()
    })
    .catch(()=>{
        btnLoading.value = false;
    })
}

// 详情
const getDetail = (id) =>{
    loading.value = true
    http.get({
        method:'detail',
        t:'RISK_JUDGEMENT',
        id:id
    },true,false)
    .then(res=>{
        loading.value = false
        var dMap = res.resultData.map;
        Object.keys(detailForm).map(key=>{
            if(key!='method'&&key!='t'&&key!='queryId'){
                detailForm[key] = dMap[key]
                state[key] = dMap[key]
            }
        });
        getStateDisplay()
    })
}
//处理回显
const getStateDisplay = () =>{
    contractorIds.value = detailForm.ct_contractor_info_id?detailForm.ct_contractor_info_id:[]

    let arr = []
    if(detailForm.ct_contractor_info_id && detailForm.ct_contractor_info_id.length>0){
        detailForm.ct_contractor_info_id.forEach(item=>{
            let elem = options.ct_contractor_info_id.find(e=>e.value==item)
            if(elem){
                arr.push(elem.label)
            }   
        })
    }
    state.ct_contractor_info_id = arr.join(',')

    state.risk_judgement_date = dayjs(detailForm.risk_judgement_date).format('YYYY-MM-DD')
}
// 新增编辑之后执行事件
const replaceRoute = (id) =>{
    router.replace({
        name:'ESGDetail',
        params:{
            id:id
        }
    })
}
const can_submit = ref(0)
const can_help_submit = ref(0)
const getPermission = () =>{
    http.get({
        method:'query',
        queryId:3026,
        t:'RISK_JUDGEMENT',
        type:'4',
    },true)
    .then(res=>{
        can_submit.value = res.resultData.can_submit;
        can_help_submit.value = res.resultData.can_help_submit;
    })
}

onMounted(()=>{
    getPermission();
    if(route.params.id==0){
        saveRequest(0,true)
    }else{
        getSelectList();
    }
});
  </script>